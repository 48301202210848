import { TallGameCardElement } from 'components/Cards/TallGameCard/styles';
import { useTranslation } from 'next-i18next';

import colors from '../../assets/styles/colors';
import { LogoStar, ShuffleTall } from '../../assets/svgs';
import { Flex } from '../commonStyles';

import {
  ViewAllBottom,
  ViewAllCardElement,
  ViewAllCenter,
  ViewAllContents,
  ViewAllText,
  ViewAllTop,
} from './styles';

const ViewAllCard = () => {
  const { t } = useTranslation();

  return (
    <TallGameCardElement>
      <ViewAllCardElement>
        <ViewAllTop />
        <ViewAllBottom />

        <ViewAllContents>
          <Flex justify="space-between">
            <LogoStar color={colors.darkViolet} />
            <ViewAllText>{t('btnViewAll')}</ViewAllText>
          </Flex>
          <ViewAllCenter>
            <ShuffleTall color={colors.darkViolet} />
          </ViewAllCenter>
          <Flex justify="flex-end">
            <LogoStar color={colors.darkViolet} />
          </Flex>
        </ViewAllContents>
      </ViewAllCardElement>
    </TallGameCardElement>
  );
};

export default ViewAllCard;
