import { pxToRem } from 'assets/styles/convertors';
import UI from 'constants/ui';
import styled from 'styled-components';

import colors from '../../../assets/styles/colors';
import { GameCardPreviewElement } from '../styles';

export const TALL_GAME_CARD_ASPECT_RATIO = 2035 / 2850;

export const TallGameCardElement = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${Math.pow(TALL_GAME_CARD_ASPECT_RATIO, -1) * 100}%;
  cursor: pointer;

  // When loading; give some dimension to the card
  background-color: ${colors.gray800};
  border-radius: ${UI.radius.md};
  transition-duration: 300ms;

  img {
    border-radius: ${UI.radius.md};
  }
`;

export const TallGameCardWrapper = styled.div`
  /* Only enable the hover effect on devices that support it */
  @media (hover: hover) {
    &:hover {
      ${TallGameCardElement} {
        transform: translate(0, ${pxToRem(-15)});
      }
      ${GameCardPreviewElement} {
        visibility: visible;
        background-color: #7717ffcc;
        transition-delay: ${UI.duration.delay};
      }
    }
  }
`;
