import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { ComponentSize } from 'components/commonStyles';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

import { mediaQueries } from '../../assets/styles/mediaQueries';

export const CarouselViewAll = styled.div<{ $size: Omit<ComponentSize, 'lg'> }>`
  border-radius: ${pxToRem(20)};
  display: none;
  font-size: ${UI.text.lg};
  font-weight: 400;
  height: ${({ $size }) => pxToRem($size === 'md' ? 40 : 30)};
  line-height: ${({ $size }) => pxToRem($size === 'md' ? 40 : 30)};
  padding: 0 ${UI.spacing.md};
  background-color: ${colors.gray700};
  border: none;
  color: ${colors.white};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.gray600};
    }
  }

  ${mediaQueries(Breakpoint.SM)`
    display: flex;
  `};
`;

export const CarouselControlButton = styled.button<{ $size: Omit<ComponentSize, 'lg'> }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  height: ${({ $size }) => pxToRem($size === 'md' ? 40 : 30)};
  width: ${({ $size }) => pxToRem($size === 'md' ? 40 : 30)};
  background-color: ${colors.gray700};
  border: none;
  color: ${colors.white};
  border-radius: ${UI.radius.full};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.gray600};
    }
  }

  &:focus {
    border: ${pxToRem(2)} solid ${colors.primaryViolet};
  }

  &:disabled {
    background-color: ${colors.gray800};
    color: ${colors.white};
    cursor: default;

    svg {
      opacity: 0.33;
    }
  }
`;

export const CarouselFittedBody = styled.div<{ $gap: string; $allItemsFit: boolean }>`
  position: relative;

  /* 
    Make room for internal cards expanding on hover;
    because swiper has overflow: hidden, cards get cut.
    Padding is placed here instead of the CarouselFittedBody because of safari hover issue
  */
  .swiper {
    padding-top: ${UI.spacing.md};
    overflow-x: clip;
    overflow-y: visible;
    z-index: 0;
  }
`;

export const CarouselFade = styled.div<{ $itemWidth: number; $fadeInto: string }>`
  z-index: ${UI.z.base};
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${({ $itemWidth }) => pxToRem(Number($itemWidth.toFixed(4)))};
  cursor: pointer;
  background: linear-gradient(
    -90deg,
    ${({ $fadeInto }) => $fadeInto}E5 0%,
    ${({ $fadeInto }) => $fadeInto}00 100%
  );
`;

export const ViewAllCardElement = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const ViewAllTop = styled.div`
  width: 100%;
  height: 100%;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background: ${colors.lightViolet};
  border-radius: ${UI.radius.sm};
  position: absolute;
`;

export const ViewAllBottom = styled.div`
  width: 100%;
  height: 100%;
  clip-path: polygon(100% 0, 0% 100%, 0 0);
  background: ${colors.primaryNeonPurple};
  border-radius: ${UI.radius.sm};
  position: absolute;
`;

export const ViewAllText = styled.div`
  font-weight: 400;
  font-size: ${UI.text.md};
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const ViewAllContents = styled.div`
  display: flex;
  flex-direction: column;
  z-index: ${UI.z.base};
  padding: ${UI.spacing.xs};
  flex: 1 1 auto;
  justify-content: space-between;
  border-radius: ${UI.radius.sm};
  border: ${pxToRem(2)} solid ${colors.primaryViolet};
`;

export const ViewAllCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
