import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { magicMediaQueries, mediaQueries } from 'assets/styles/mediaQueries';
import { H3 } from 'components/Text/styles';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const TALL_GAME_CARD_ASPECT_RATIO = 2035 / 2850;

export const HeroBannerCardElement = styled.a<{ $aspectRatio: number }>`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: ${({ $aspectRatio }) => 100 * (1 / $aspectRatio)}%;
  border-radius: ${UI.radius.md};
  cursor: pointer;
  transition-duration: 300ms;

  img {
    border-radius: ${UI.radius.md};
  }

  /* Only enable the hover effect on devices that support it */
  @media (hover: hover) {
    &:hover {
      transform: translate(0, ${pxToRem(-7.5)});
    }
  }
`;

export const CardGridWrapper = styled.div`
  position: relative;
  margin-bottom: ${UI.spacing.md};

  ${H3} {
    margin-bottom: ${UI.spacing.md};
    height: ${pxToRem(40)};
    display: flex;
    align-items: center;
    gap: ${UI.spacing.xs};
    font-size: ${UI.text.h4};

    ${magicMediaQueries(Breakpoint.SM)`
     font-size: ${UI.text.h3};
    `};
  }
`;

export const CardGridElement = styled.div<{ $columns: number; $gap: string }>`
  position: relative;
  display: grid;
  gap: ${({ $gap }) => $gap};
  grid-template-columns: repeat(${({ $columns }) => $columns}, minmax(0, 1fr));
`;

export const CategoryCardWrapper = styled.div`
  cursor: pointer;
  background-color: ${colors.gray900};
  padding: 0 ${UI.spacing.md};
  border-radius: ${UI.radius.md};
  transition-duration: 300ms;

  /* Only enable the hover effect on devices that support it */
  @media (hover: hover) {
    &:hover {
      transform: translate(0, ${pxToRem(-15)});
    }
  }
`;

export const CategoryCardElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  min-height: ${pxToRem(50)};
  text-transform: capitalize;
  padding: 29% 0;
  column-gap: ${UI.spacing.sm};
  font-size: ${UI.text.lg};

  ${mediaQueries(Breakpoint.XS)`
    font-size: ${UI.text.h4};
    min-height: 0;
  `};
`;

export const GameCardPreviewBody = styled.a`
  display: flex;
  flex: 1;
  opacity: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const GameCardPreviewElement = styled.div`
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 200;
  visibility: hidden;
  background-color: #7717ff00;
  transition: visibility 0s, background-color ${UI.duration.normal} linear;
  border-radius: ${UI.radius.md};
`;

const HPadding = pxToRem(15);
const VPadding = pxToRem(10);

export const GameCardPreviewTop = styled.div`
  position: absolute;
  top: ${VPadding};
  left: ${HPadding};
`;

export const GameCardPreviewBottomLeft = styled.div`
  position: absolute;
  bottom: ${VPadding};
  left: ${HPadding};
`;

export const GameCardPreviewBottomRight = styled.div`
  position: absolute;
  bottom: ${VPadding};
  right: ${HPadding};
`;

export const GameCardPreviewTitleSection = styled.div`
  position: relative;
`;

export const GameCardPreviewName = styled.a`
  display: flex;
  font-size: ${UI.text.xl};
  font-weight: 700;
  padding-right: ${HPadding};
  white-space: normal;
  margin-bottom: ${UI.spacing.xs};
`;

export const GameCardPreviewProvider = styled.div`
  position: absolute;
  bottom: ${pxToRem(-27)};
  white-space: nowrap;

  a {
    font-size: ${UI.text.lg};
    font-weight: 400;
    line-height: 125%;
  }
`;

export const GameCardBorder = styled.div<{ $accent?: string }>`
  position: absolute;
  inset: 0;
  border: 2px solid ${({ $accent }) => $accent ?? 'transparent'};
  border-radius: ${UI.radius.md};

  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const CardGridDisplayingText = styled.span`
  font-size: ${UI.text.lg};
`;

export const ProviderCardBlock = styled.div`
  background-color: ${colors.gray900};
  border-radius: ${UI.radius.md};
  transition-duration: 300ms;
  padding: ${pxToRem(14)} ${pxToRem(12)};

  ${mediaQueries(Breakpoint.MD)`
    padding: ${pxToRem(18)} ${UI.spacing.xl};
  `}
`;

export const ProviderCardWrapper = styled.div<{ $canClick?: boolean }>`
  cursor: ${({ $canClick }) => ($canClick ? 'pointer' : 'default')};

  /* Only enable the hover effect on devices that support it */
  @media (hover: hover) {
    &:hover {
      ${ProviderCardBlock} {
        transform: translate(0, ${pxToRem(-15)});
      }
    }
  }
`;

export const ProviderCardElement = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 44.8599%;
`;

export const ProviderImage = styled.img`
  position: absolute;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const CardGridEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.625rem;
  height: ${pxToRem(448)};
  border: 1px dashed ${colors.gray700};
  border-radius: ${UI.radius.md};
  background-color: ${colors.gray900};

  p {
    color: ${colors.white};
    font-size: ${pxToRem(20)};
    font-weight: 700;
  }

  ${magicMediaQueries(Breakpoint.SM)`
     height: ${pxToRem(350)};
  `}
`;
