import React from 'react';

import { SvgProps } from '.';

const ShuffleTall = ({ scale = 1, color = '#ffffff', viewBox = '0 0 63 73' }: SvgProps) => {
  const width = `${scale * 63}`;
  const height = `${scale * 73}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_2852_61644)">
        <path
          d="M47.6216 0.0990295H15.5479C11.4423 0.0990295 7.57765 1.71066 4.67647 4.62233C1.77528 7.53938 0.174805 11.4127 0.174805 15.5331V20.2605C0.174805 26.5566 4.11978 32.0737 10.7304 35.023L13.3693 36.1082V20.4915C13.3586 20.4109 13.3533 20.3357 13.3533 20.2551V15.5277C13.3533 14.7272 13.7601 14.2062 14.001 13.9644C14.2418 13.7227 14.761 13.3144 15.5532 13.3144H47.6269C48.4245 13.3144 48.9437 13.7173 49.1846 13.9644C49.4254 14.2062 49.8269 14.7272 49.8269 15.5223V25.8958H63V15.5277C63 11.4073 61.3995 7.52864 58.4983 4.61696C55.5864 1.70529 51.7271 0.0990295 47.6216 0.0990295Z"
          fill={color}
        />
      </g>
      <g filter="url(#filter1_i_2852_61644)">
        <path
          d="M52.3909 37.8757L49.8055 36.8121V52.5362C49.8162 52.5738 49.8162 52.6168 49.8162 52.6544V57.3818C49.8162 58.1823 49.4147 58.7087 49.1685 58.9505C48.9276 59.1922 48.4084 59.6005 47.6162 59.6005H15.5479C14.3382 59.6005 13.3533 58.6067 13.3533 57.3979V47.0244H0.174805V57.3979C0.174805 65.9073 7.06914 72.8266 15.5479 72.8266H47.6216C51.7271 72.8212 55.5918 71.215 58.493 68.3033C61.3942 65.3862 62.9946 61.5076 62.9946 57.3872V52.6598C62.9946 46.3046 59.0336 40.7767 52.3909 37.8811V37.8757Z"
          fill={color}
        />
      </g>
      <g filter="url(#filter2_i_2852_61644)">
        <path
          d="M25.9215 42.146C30.5248 46.766 31.5847 52.6163 31.5847 52.6163C31.5847 52.6163 32.2752 48.8504 34.9248 44.9771C35.5778 44.0209 36.3433 43.0593 37.2532 42.146C41.8566 37.526 47.6857 36.457 47.6857 36.457C47.6857 36.457 43.2858 35.6512 39.1053 32.4064C38.479 31.9176 37.8528 31.3804 37.2479 30.7733C32.6445 26.1533 31.5793 20.2977 31.5793 20.2977C31.5793 20.2977 30.8995 24.0636 28.2499 27.9476C27.5969 28.9038 26.8261 29.8654 25.9161 30.7787C21.3074 35.3987 15.4783 36.4624 15.4783 36.4624C15.4783 36.4624 19.8836 37.2682 24.0641 40.5129C24.6903 41.0072 25.3166 41.5444 25.9215 42.1514V42.146Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2852_61644"
          x="0.174805"
          y="0.0990295"
          width="62.8252"
          height="38.0091"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2852_61644" />
        </filter>
        <filter
          id="filter1_i_2852_61644"
          x="0.174805"
          y="36.8121"
          width="62.8198"
          height="38.0145"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2852_61644" />
        </filter>
        <filter
          id="filter2_i_2852_61644"
          x="15.4783"
          y="20.2977"
          width="32.2075"
          height="34.3185"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2852_61644" />
        </filter>
      </defs>
    </svg>
  );
};

export default ShuffleTall;
