import React from 'react';

import { SvgProps } from '.';

const LogoStar = ({ scale = 1, viewBox = '0 0 23 21', color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 23}`;
  const height = `${scale * 21}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g filter="url(#filter0_i_2852_61643)">
        <path
          d="M23 10.5146C19.9481 10.5136 17.0215 9.40543 14.8638 7.43366C12.7061 5.4619 11.494 2.78802 11.494 0C11.494 2.78477 10.283 5.45549 8.12749 7.42462C5.97194 9.39375 3.0484 10.5 0 10.5C3.0484 10.5 5.97194 11.6062 8.12749 13.5754C10.283 15.5445 11.494 18.2152 11.494 21C11.4945 19.621 11.7927 18.2557 12.3715 16.9821C12.9502 15.7085 13.7982 14.5516 14.8669 13.5777C15.9356 12.6038 17.204 11.832 18.5996 11.3064C19.9952 10.7808 21.4905 10.5117 23 10.5146Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2852_61643"
          x="0"
          y="0"
          width="23"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2852_61643" />
        </filter>
      </defs>
    </svg>
  );
};

export default LogoStar;
