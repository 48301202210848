export enum CarouselType {
  PROMOTIONS = 'PROMOTIONS',
  GAME_CARDS = 'GAME_CARDS',
  PROVIDERS = 'PROVIDERS',
  SHUFFLE_CARDS = 'SHUFFLE_CARDS',
  VIP_REWARDS = 'VIP_REWARDS',
}

const rangeWithBreakpoint: Record<CarouselType, Record<1 | 2 | 3 | 4 | 5 | 6 | 7, number>> = {
  [CarouselType.PROMOTIONS]: {
    1: 1,
    2: 2,
    3: 2,
    4: 2,
    5: 3,
    6: 3,
    7: 3,
  },
  [CarouselType.GAME_CARDS]: {
    1: 3,
    2: 4,
    3: 4,
    4: 4.5,
    5: 5.5,
    6: 6.5,
    7: 7.5,
  },
  [CarouselType.PROVIDERS]: {
    1: 3,
    2: 4,
    3: 4,
    4: 4.5,
    5: 5.5,
    6: 6.5,
    7: 7.5,
  },
  [CarouselType.SHUFFLE_CARDS]: {
    1: 3,
    2: 4,
    3: 4,
    4: 4,
    5: 4,
    6: 5,
    7: 5,
  },
  [CarouselType.VIP_REWARDS]: {
    1: 1,
    2: 2,
    3: 3,
    4: 3,
    5: 4,
    6: 4,
    7: 5,
  },
};

export const calculateCardCount = (parentWidth: number, carouselType: CarouselType) => {
  const carouselWithType = rangeWithBreakpoint[carouselType];

  if (parentWidth > 1150) return carouselWithType[7];
  if (parentWidth > 1000) return carouselWithType[6];
  if (parentWidth > 850) return carouselWithType[5];
  if (parentWidth > 700) return carouselWithType[4];
  if (parentWidth > 600) return carouselWithType[3];
  if (parentWidth > 500) return carouselWithType[2];
  return carouselWithType[1];
};
