import React, { ReactNode, useMemo, useRef } from 'react';
import { useMeasure } from 'react-use';
import { pxToRem } from 'assets/styles/convertors';
import { ComponentSize, Flex } from 'components/commonStyles';
import UI from 'constants/ui';
import { Breakpoint, defaultBreakpoints } from 'hooks/interface/useBreakpoint';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { FreeMode, Mousewheel, Navigation, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import colors from '../../assets/styles/colors';
import { Chevron } from '../../assets/svgs';
import { checkIsMobileFromUserAgent } from '../../utils/userAgent';

import { CarouselControlButton, CarouselFade, CarouselFittedBody, CarouselViewAll } from './styles';
import ViewAllCard from './ViewAllCard';

import 'swiper/css';
import 'swiper/css/pagination';
import styles from './FittedCarousel.module.scss';

// Limit carousel to 20 items by default
const MAX_ITEM_DEFAULT = 20;

interface FittedCarouselProps {
  slidesPerView?: number;
  title?: ReactNode | string;

  size?: Omit<ComponentSize, 'lg'>;

  /**
   * Include the dark fade overlay on the right?
   * Setting to `auto` will fade if the `fit` is non-integer.
   */
  fade?: boolean | 'auto';
  /** Fade into which color? */
  fadeInto?: string;

  disabled?: boolean;

  href?: string;

  // How many items to show before View All card
  maxItems?: number;

  hideViewAll?: boolean;
  hideViewAllButton?: boolean;
  hideHeader?: boolean;
  allowTouchMove?: boolean;

  // How many items to scroll at a time
  slidesPerGroup?: number;
  // Enable infinite loop
  loop?: boolean;

  navigatePrevComponent?: (onClick: () => void, hasPrevPage: boolean) => ReactNode;
  navigateNextComponent?: (onClick: () => void, hasNextPage: boolean) => ReactNode;

  /**
   *
   * @param width Width of the parent of the carousel
   * @returns the number of cards per view
   */
  fitCardsFunc: (width: number) => number;
  children: React.ReactNode;
}

const navClickDistance = Array(3).fill(0);

/**
 * A FittedCarousel which takes a set number of items to display per scroll.
 * Supports fractional fit; eg fit 6.5 cards across to indicate scrollable behaviour.
 *
 * This component is intended to be used with loading polyfill cards as children and
 * therefore does not expect to have an empty state. If there is a condition where
 * you have zero children, don't use this component.
 */
const FittedCarousel = (props: FittedCarouselProps) => {
  const { t } = useTranslation();

  const {
    title,
    children,
    fade = 'auto',
    size = 'md',
    disabled,
    href = '',
    maxItems = MAX_ITEM_DEFAULT,
    hideViewAll = false,
    hideViewAllButton = false,
    hideHeader = false,
    allowTouchMove = true,
    loop = false,
    navigatePrevComponent,
    navigateNextComponent,
    fitCardsFunc,
  } = props;

  const [parentRef, { width: parentWidth }] = useMeasure<HTMLDivElement>();
  const gap = parentWidth >= 700 ? '1rem' : pxToRem(6);

  const swiperRef = useRef<SwiperType>();
  const sliderRef = useRef<SwiperRef>(null);
  const [slideIdx, setSlideIdx] = React.useState(0);

  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const slidesPerView = fitCardsFunc(parentWidth);
  const itemWidth = useMemo(() => width / slidesPerView, [width, slidesPerView]);
  const isMobile = checkIsMobileFromUserAgent();

  // Limit to length of maxItems
  const items = React.Children.toArray(children).slice(0, maxItems - 1);
  if (!hideViewAll && items.length > slidesPerView) {
    items.push(
      <Link href={href} passHref>
        <a>
          <ViewAllCard />
        </a>
      </Link>,
    );
  }

  const hasPrevPage = slideIdx > 0;
  const hasNextPage = slideIdx + Math.floor(slidesPerView) < items.length;

  // Non-integer `fit` will fade when set to `auto`.
  // Only fade if there is a next page
  const shouldFade = (fade === true || (fade === 'auto' && slidesPerView % 1 > 0)) && hasNextPage;
  const fadeInto = props.fadeInto ?? colors.grayScaleBlack;
  const allItemsFit = slidesPerView === items.length;

  const navigatePrev = () => {
    if (props.slidesPerGroup === 1) {
      swiperRef.current?.slidePrev(600);
    } else {
      navClickDistance.forEach(() => swiperRef.current?.slidePrev(600));
    }
  };

  const navigateNext = () => {
    if (props.slidesPerGroup === 1) {
      swiperRef.current?.slideNext(600);
    } else {
      navClickDistance.forEach(() => swiperRef.current?.slideNext(600));
    }
  };

  return (
    <section className={styles.root} ref={parentRef}>
      {!hideHeader && (
        <div
          className={`${styles.carouselFittedHeader} ${
            size === 'md' ? '' : styles.carouselFittedHeaderSmallText
          }`}
        >
          {href ? (
            <Link href={href} passHref>
              <a>
                <h3>
                  <Flex align="center">{title}</Flex>
                </h3>
              </a>
            </Link>
          ) : (
            <h3>
              <Flex align="center">{title}</Flex>
            </h3>
          )}

          <Flex gap={UI.spacing.sm} align="center">
            {!hideViewAllButton && (
              <Link href={href} passHref>
                <a>
                  <CarouselViewAll $size={size}>{t('btnViewAll')}</CarouselViewAll>
                </a>
              </Link>
            )}

            {!navigatePrevComponent && (
              <CarouselControlButton
                $size={size}
                onClick={navigatePrev}
                disabled={!hasPrevPage || disabled}
              >
                <Chevron direction="left" />
              </CarouselControlButton>
            )}

            {!navigateNextComponent && (
              <CarouselControlButton
                $size={size}
                onClick={navigateNext}
                disabled={!hasNextPage || disabled}
              >
                <Chevron direction="right" />
              </CarouselControlButton>
            )}
          </Flex>
        </div>
      )}

      <CarouselFittedBody ref={ref} $allItemsFit={allItemsFit} $gap={gap}>
        {navigatePrevComponent?.(navigatePrev, hasPrevPage)}
        {navigateNextComponent?.(navigateNext, hasNextPage)}
        <Swiper
          allowTouchMove={allowTouchMove}
          slidesPerView={props.slidesPerView ?? slidesPerView}
          slidesPerGroup={1}
          spaceBetween={8}
          speed={150}
          loop={loop}
          threshold={5}
          mousewheel
          onBeforeInit={swiper => {
            swiperRef.current = swiper;
          }}
          cssMode={isMobile}
          onSlideChange={swiper => setSlideIdx(swiper.activeIndex)}
          modules={[FreeMode, Navigation, Mousewheel]}
          ref={sliderRef}
          breakpoints={{
            [defaultBreakpoints[Breakpoint.MD]]: {
              spaceBetween: 16,
              freeMode: {
                enabled: true,
                sticky: true,
              },
              mousewheel: {
                forceToAxis: true,
              },
            },
          }}
        >
          {items.map((child, i) => (
            <SwiperSlide key={i}>
              <div className={styles.carouselItemFitted}>{child}</div>
            </SwiperSlide>
          ))}
        </Swiper>

        {shouldFade && (
          <CarouselFade $fadeInto={fadeInto} $itemWidth={itemWidth / 2} onClick={navigateNext} />
        )}
      </CarouselFittedBody>
    </section>
  );
};

export default FittedCarousel;
